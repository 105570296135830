define("discourse/plugins/attachments-list/discourse/templates/connectors/admin-menu/attachments-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <NavItem @route="adminAttachments" @label="admin.manage_attachments.title" />
  */
  {
    "id": "j07pevUS",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@label\"],[\"adminAttachments\",\"admin.manage_attachments.title\"]],null]],[],false,[\"nav-item\"]]",
    "moduleName": "discourse/plugins/attachments-list/discourse/templates/connectors/admin-menu/attachments-list.hbs",
    "isStrictMode": false
  });
});