define("discourse/plugins/attachments-list/discourse/initializers/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'alert',
    initialize() {
      console.log('test');
    }
  };
});