define("discourse/plugins/attachments-list/discourse/attachments-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("admin", {
      resetNamespace: true
    }, function () {
      this.route("adminAttachments", {
        path: "/attachments",
        resetNamespace: true
      });
    });
  }
});