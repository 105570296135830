define("discourse/plugins/attachments-list/discourse/controllers/admin-attachments", ["exports", "@ember/controller", "@ember/service", "@ember/object", "discourse-common/utils/decorators", "@ember-decorators/object", "discourse-common/lib/debounce", "discourse/plugins/attachments-list/discourse/models/attachment", "discourse-common/config/environment", "discourse/lib/computed"], function (_exports, _controller, _service, _object, _decorators, _object2, _debounce, _attachment, _environment, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminAttachmentsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    listFilter = null;
    model = null;
    query = null;
    order = null;
    asc = null;
    showEmails = false;
    refreshing = false;
    listFilter = null;
    selectAll = false;
    _page = 1;
    _results = [];
    _canLoadMore = true;
    static #_2 = (() => dt7948.g(this.prototype, "searchHint", [(0, _computed.i18n)("admin.uploads.search_hint")]))();
    #searchHint = (() => (dt7948.i(this, "searchHint"), void 0))();
    _filterUsers() {
      (0, _debounce.default)(this, this.resetFilters, _environment.INPUT_DELAY);
    }
    static #_3 = (() => dt7948.n(this.prototype, "_filterUsers", [(0, _object2.observes)("listFilter")]))();
    resetFilters() {
      this._page = 1;
      this._results = [];
      this._canLoadMore = true;
      this._refreshUploads();
    }
    _refreshUploads() {
      if (!this._canLoadMore) {
        return;
      }
      const page = this._page;
      this.set("refreshing", true);
      _attachment.default.findAll({
        filter: this.listFilter,
        order: this.order,
        asc: this.asc,
        page
      }).then(result => {
        this._results[page] = result;
        this.set("model", this._results.flat());
        if (result.length === 0) {
          this._canLoadMore = false;
        }
      }).finally(() => {
        this.set("refreshing", false);
      });
    }
    loadMore() {
      this._page += 1;
      this._refreshUploads();
    }
    static #_4 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    columnCount() {
      let colCount = 5; // note that the first column is hardcoded in the template

      return colCount;
    }
    static #_5 = (() => dt7948.n(this.prototype, "columnCount", [_decorators.default]))();
  }
  _exports.default = AdminAttachmentsController;
});