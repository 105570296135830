define("discourse/plugins/attachments-list/discourse/models/attachment", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Upload {
    static findAll(userFilter) {
      return (0, _ajax.ajax)(`/admin/attachments.json`, {
        data: userFilter
      }).then(uploads => uploads);
    }
  }
  _exports.default = Upload;
});